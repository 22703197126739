<template>
  <div class="one-click-open-door" :class="{ 'show-bg': openDoorStatusFlag === 1 }">
    <div class="kmcg-box">
      <img v-show="openDoorStatusFlag === '1'" src="./img/kmcg.png" />
      <img v-show="openDoorStatusFlag === '2'" src="./img/kmsb.png" />
      <div v-show="openDoorStatusFlag === '2'" class="back-btn" @click="backHand">{{ djs }} 返回重新开门</div>
    </div>
    <div class="kmjl-box">
      <img class="kmjl-btn" src="./img/kmjl_btn.png" @click="kmlsjlOnClick" />
      <div class="kmjl-text" @click="kmlsjlOnClick">历史开门记录</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'oneClickOpenDoor',
  data() {
    return {
      openDoorStatusFlag: 1, // 1开门成功  2开门失败
      djs: 5,
      timer: null
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  created() {
    this.openDoorStatusFlag = this.$route.query.value;
  },
  mounted() {
    this.openDoorStatusFlag === '2' && this.startInterval();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startInterval() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.djs--;
        if (this.djs <= 0) {
          clearInterval(this.timer);
          this.backHand();
        }
      }, 1000);
    },
    backHand() {
      this.$router.go(-1);
    },
    kmlsjlOnClick() {
      this.$router.push({
        name: 'openDoorRecordList'
      });
    }
  }
};
</script>

<style lang="less" scoped>
.one-click-open-door {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  .kmcg-box,
  .kmsb-box {
    box-sizing: border-box;
    width: 480px;
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 480px;
    }
    .back-btn {
      box-sizing: border-box;
      margin-top: 30px;
      border: 1px solid #fba15b;
      border-radius: 4px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      font-size: 28px;
      color: #fba15b;
    }
  }
  .kmjl-box {
    box-sizing: border-box;
    position: fixed;
    left: 50%;
    bottom: 30px;
    bottom: calc(30px + constant(safe-area-inset-bottom));
    bottom: calc(30px + env(safe-area-inset-bottom));
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .kmjl-btn {
      width: 100px;
      height: 100px;
    }
    .kmjl-text {
      font-size: 28px;
      line-height: 40px;
      color: #666666;
      margin-top: 10px;
    }
  }
}
</style>
